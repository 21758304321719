import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <div>
      <p>Loading</p>
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `puparchitects`,
  linkResolver: ({ node, key, value }) => doc => {
    if (doc.type === "project") {
      return `/projects/${doc.uid}`;
    } else if (doc.type === "discourse") {
      return `/discourse/${doc.uid}`;
    } else if (doc.type === "homepage") {
      return `/`;
    } else {
      return `/${doc.uid}`;
    }
  },
});
